<template>
    <v-card flat outlined>
        <v-card-text style="background-color:#eeeeee;">
            <v-row>
                <v-col class="text-center mt-12 mb-12" style="font-size:24px; font-weight:600;">결제수단을 등록합니다</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center">
                    <v-btn color="#CCCCCC" width="94px" dark elevation="0" style="font-size:16px;" @click="doPrev">취소</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import {mapGetters} from 'vuex'
import payApi from '@/api/pay'
import CONF from '@/config.js'

export default {
    name: 'pay_forward_pg',
    components: {
//    TileStats, TileStaffs
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            companyPay: 'pay/getCompanyPay',
        }),
    },
    created() {
    },
    mounted() {
        const plugin = document.createElement("script")
        plugin.setAttribute("src", "https://stdpay.inicis.com/stdjs/INIStdPay.js")
        plugin.setAttribute("charset", "UTF-8")
        plugin.async = true;
        document.head.appendChild(plugin)

        let ctx = this
        setTimeout(function () {
            ctx.goPay()
        }, 500)
    },
    methods: {
        doPrev() {
            this.$router.push('/pay/status')
        },
        goPay() {
            let ctx = this
            payApi.startBillKeyTran({}, (pgReq) => {

                let pgForm = document.createElement('form')
                pgForm.name = 'pgForm'
                pgForm.id = 'pgForm'
                pgForm.method = 'post'
                pgForm.action = CONF.PG_BASE_URL + '/bill'

                let goodname = '콜 PRO 서비스'

                ctx.addHiddens({
                    'version': '1.0',
                    'mid': pgReq.mid,
                    'goodname': goodname,
                    'oid': pgReq.oid,
                    'price': pgReq.price,
                    'currency': 'WON',
                    'buyername': pgReq.buyer_name,
                    'buyertel': pgReq.buyer_tel,
                    'buyeremail': pgReq.buyer_email,
                    'timestamp': pgReq.timestamp,
                    'signature': pgReq.signature,
                    'returnUrl': pgReq.return_url,
                    'mKey': pgReq.m_key,
                    'gopaymethod': '',
                    'offerPeriod': 'M2',
                    'acceptmethod': 'BILLAUTH(card)',
                    'billPrint_msg': '고객님의 매월 결제일은 01일 입니다.',
                    'languageView': '',
                    'charset': '',
                    'payViewType': '',
                    'closeUrl': pgReq.close_url,
                    'popupUrl': pgReq.popup_url,
                    'merchantData': pgReq.merchant_data
                }, pgForm)

                let oldObj = document.getElementById('pgForm')
                if (oldObj) {
                    document.body.removeChild(oldObj)
                }
                document.body.appendChild(pgForm)
                //pgForm.submit()

                INIStdPay.pay('pgForm')
            }, (err) => {
                console.log('Fail : ' + err)
                alert("결제 서비스 연동을 실패 했습니다")
            })

        },
        addHiddens(m, f) {
            for (let ke in m) {
                let va = m[ke]

                let i = document.createElement('input')
                i.setAttribute('type', 'hidden')
                i.setAttribute('name', ke)
                i.setAttribute('value', va)
                f.appendChild(i)
            }
        },
    }
}
</script>
<style>
</style>


